import {
    BalButton,
    BalCard,
    BalCardContent,
    BalHeading,
    BalText,
} from "@baloise/ds-react";
import { MediaQuery } from "@lg-cfa/enums";
import { useMediaQuery } from "@lg-cfa/hooks";
import classnames from "classnames";
import { navigate } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";

import { fetchIconByName } from "../../helpers/icon";

import { ServerErrorProps } from "./ServerErrorTypes.types";

const ServerError: React.FC<ServerErrorProps> = ({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onContactClick = () => {},
}) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery(MediaQuery.Mobile);

    const handleBack = () => {
        navigate(-1);
    };
    return (
        <main className="container mt-xxxx-large pb-xxxx-large">
            <div className="container is-compact">
                <BalButton
                    icon="caret-left"
                    className="mb-medium"
                    color="link"
                    flat
                    onClick={handleBack}
                >
                    {t("serverErrorPage.back")}
                </BalButton>
                <BalCard flat square>
                    <BalCardContent className="px-xxx-large py-x-large">
                        <div className="flex flex-direction-column align-items-center text-align-center">
                            <img
                                src={fetchIconByName(
                                    "ComputerConnectionBrowserError",
                                )}
                                className="mb-large"
                            />
                            <BalHeading
                                level="h3"
                                space="none"
                                className="mb-normal"
                            >
                                <span className="font-weight-light">
                                    {t("serverErrorPage.title")}
                                </span>
                            </BalHeading>
                            <BalText space="none" className="mb-medium">
                                {t("serverErrorPage.text")}
                            </BalText>
                            <div
                                className={classnames(
                                    "flex gap-medium justify-content-center",
                                    {
                                        "flex-direction-column": isMobile,
                                    },
                                )}
                            >
                                <BalButton
                                    icon="refresh"
                                    rounded
                                    onClick={handleBack}
                                    expanded={isMobile}
                                >
                                    {t("serverErrorPage.button.refresh")}
                                </BalButton>
                                <BalButton
                                    icon="call"
                                    color="info"
                                    rounded
                                    onClick={onContactClick}
                                    expanded={isMobile}
                                >
                                    {t("serverErrorPage.button.contact")}
                                </BalButton>
                            </div>
                        </div>
                    </BalCardContent>
                </BalCard>
            </div>
        </main>
    );
};

export default ServerError;
