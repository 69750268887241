import {
    BalCard,
    BalCardContent,
    BalHeading,
    BalIcon,
    BalStack,
    BalText,
} from "@baloise/ds-react";
import { Table, TableColumn } from "@lg-cfa/components";
import classnames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import { useAnchorMenu } from "../../hooks/useAnchorMenu";

import { ColumnKeys } from "./InsuranceTypes.enums";
import * as style from "./InsuranceTypesInfo.module.scss";
import { InsuranceTypesInfoProps } from "./InsuranceTypesInfo.types";
import {
    createColumnHeaderData,
    createColumns,
    createTableData,
} from "./insuranceTypes.helpers";

import Check from "@images/Ford-product-page/check.svg";
import { selectInsuranceTypesInfo, selectMenu } from "@modules/shared/state";

const InsuranceTypesInfo: React.FC<InsuranceTypesInfoProps> = ({
    className,
}) => {
    const data = useRecoilValue(selectInsuranceTypesInfo);
    const menuItems = useRecoilValue(selectMenu);
    const columnHeadersData = createColumnHeaderData(data);
    const columns = createColumns(columnHeadersData);
    const tableData = createTableData(data);
    const { t } = useTranslation();
    const anchorId =
        t("product-page.anchor.insurance-types") ??
        "product-page-insurance-types-info";
    const { ref } = useAnchorMenu(menuItems);

    const renderMobile = () => {
        const productColumns = columns.filter(
            (col) => col.key !== ColumnKeys.Label,
        ) as TableColumn<Exclude<ColumnKeys, ColumnKeys.Label>>[];

        return (
            <div className="desktop:hidden tablet:hidden w-full flex flex-direction-column gap-medium">
                {productColumns.map((col, index) => (
                    <BalCard
                        flat
                        border
                        key={`insurance-type-info-card-${index}`}
                    >
                        <BalCardContent>
                            <BalHeading
                                level="h4"
                                color="primary"
                                space="none"
                                className="mb-xx-small"
                            >
                                {columnHeadersData[col.key].title}
                            </BalHeading>
                            <BalText
                                className={classnames(
                                    style.hasTextGreyLight,
                                    "mb-large",
                                    "text-weight-medium",
                                )}
                                size="small"
                            >
                                {columnHeadersData[col.key].subTitle}
                            </BalText>

                            <ul className={classnames("list", style.cardList)}>
                                {tableData.map((tData, dataIndex) => (
                                    <li
                                        key={`insurance-type-info-card-list-${dataIndex}`}
                                    >
                                        <div
                                            className={classnames(
                                                "flex align-items-center",
                                                {
                                                    [style.hasTextGreyLight]:
                                                        !tData[col.key],
                                                },
                                            )}
                                        >
                                            {tData[col.key] ? (
                                                <img
                                                    src={Check}
                                                    alt="icon"
                                                    width={15}
                                                    height={15}
                                                    className="mr-x-small"
                                                />
                                            ) : (
                                                <BalIcon
                                                    name="close"
                                                    color="grey-light"
                                                    className="mr-x-small"
                                                    size="small"
                                                ></BalIcon>
                                            )}

                                            <BalText size="small" space="none">
                                                {tData.label as string}
                                            </BalText>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </BalCardContent>
                    </BalCard>
                ))}
            </div>
        );
    };

    const renderDesktop = () => (
        <div className="mobile:hidden w-full">
            <Table
                loading={false}
                columns={columns}
                data={tableData}
                tableCellClassName={style.tableRow}
                tableHeaderClassName={style.tableHeader}
            ></Table>
        </div>
    );

    return (
        <section
            ref={ref}
            id={anchorId}
            className={classnames(style.cInsuranceTypeInfo, className)}
        >
            <BalStack
                layout="vertical"
                space="x-large"
                className="container is-desktop text-align-left"
                align="center"
            >
                <BalHeading level="h2" subtitle>
                    {data.advisorTitle}
                </BalHeading>
                {renderMobile()}
                {renderDesktop()}
            </BalStack>
        </section>
    );
};
export default InsuranceTypesInfo;
