import {
    BalButton,
    BalHeading,
    BalModal,
    BalModalBody,
    BalModalHeader,
    BalText,
} from "@baloise/ds-react";
import { Form } from "@baloise-cfa/form-renderer-frontend";
import { MediaQuery } from "@lg-cfa/enums";
import { useMediaQuery } from "@lg-cfa/hooks";
import classNames from "classnames";
import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import { Application } from "../../../shared.enums";

import { createFields } from "./ContactModal.helpers";
import {
    ContactModalProps,
    InnerContactModalFormValue,
} from "./ContactModal.types";

const ContactModal: React.FC<ContactModalProps> = ({
    onClose,
    onSubmit,
    initialValues = {},
    professionalUsage,
    application = Application.Simulator,
    cancelBtnText = "all.cancel",
    submitBtnText = "contactForm.submitBtnText",
    title,
    intro,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange = () => {},
}) => {
    const modalRef = useRef<HTMLBalModalElement>(null);
    const isMobile = useMediaQuery(MediaQuery.Mobile);
    const { t } = useTranslation();

    useEffect(() => {
        const currRef = modalRef.current;
        modalRef?.current?.open();

        return () => {
            onClose();
            currRef?.close();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initialFormValues: InnerContactModalFormValue = useMemo(
        () => ({
            context: {
                professionalUsage: professionalUsage,
            },
            data: initialValues,
        }),
        [professionalUsage, initialValues],
    );

    const fields = useMemo(() => createFields(application), [application]);

    return (
        <BalModal
            ref={modalRef}
            component={null}
            isClosable
            onBalModalDidDismiss={onClose}
        >
            {title && (
                <BalModalHeader>
                    <BalHeading level="h3" subtitle>
                        {t(title)}
                    </BalHeading>
                </BalModalHeader>
            )}
            <BalModalBody>
                {intro && <BalText>{t(intro)}</BalText>}
                <Form
                    formContext={{
                        t,
                        fieldWrapper: {
                            optionalLabel: "all.optional",
                        },
                        nameSpace: "data",
                    }}
                    showErrorMessageCard={false}
                    onChange={(values) => onChange(values.data)}
                    initialValues={initialFormValues}
                    fields={fields}
                    enableReinitialize
                    onSubmit={(values) => onSubmit(values.data)}
                >
                    <div
                        className={classNames(
                            "flex",
                            {
                                "flex-direction-column-reverse": isMobile,
                            },
                            {
                                "justify-content-end": !isMobile,
                            },
                        )}
                    >
                        <BalButton
                            expanded={isMobile}
                            rounded
                            color="info"
                            className={classNames(
                                {
                                    "mt-normal": isMobile,
                                },
                                {
                                    "mr-normal": !isMobile,
                                },
                            )}
                            onClick={() => onClose()}
                        >
                            {t(cancelBtnText)}
                        </BalButton>
                        <BalButton
                            color="primary"
                            rounded
                            expanded={isMobile}
                            elementType="submit"
                        >
                            {t(submitBtnText)}
                        </BalButton>
                    </div>
                </Form>
            </BalModalBody>
        </BalModal>
    );
};

export default ContactModal;
