export enum ButtonTypes {
    link = "link",
    btnPrimary = "primary",
    btnPrimaryLight = "primary-light",
    btnInfo = "info",
    btnDanger = "danger",
    btnInfoLight = "info-light",
    btnSuccess = "success",
    btnWarning = "warning",
}

export enum MediaQuery {
    Mobile = "(max-width: 769px)",
    Tablet = "(min-width: 769px)",
    Desktop = "(min-width: 1024px)",
    DesktopDown = "(max-width: 1024px)",
    Widescreen = "(min-width: 1440px)",
    FullHD = "(min-width: 1920px)",
}
