import { BalHeading, BalIcon, BalStack } from "@baloise/ds-react";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import { useAnchorMenu } from "../../hooks/useAnchorMenu";

import * as style from "./Documents.module.scss";
import { DocumentsProps } from "./Documents.types";

import { selectDocuments, selectMenu } from "@modules/shared/state";

const Document: React.FC<{ fileName: string; url: string }> = ({
    fileName,
    url,
}) => {
    return (
        <>
            <a
                href={`${process.env.GATSBY_CMS_ASSETS_URL}${url}`}
                target="_blank"
                rel="noopener noreferrer"
                className={style.cDocuments__document}
            >
                <div className={style.cDocuments__documentName}>
                    <BalIcon name="document"></BalIcon>
                    <span className="ml-small">{fileName}</span>
                </div>

                <BalIcon name="download"></BalIcon>
            </a>
        </>
    );
};

const Documents: React.FC<DocumentsProps> = ({ className }) => {
    const data = useRecoilValue(selectDocuments);
    const { t } = useTranslation();
    const menuItems = useRecoilValue(selectMenu);
    const anchorId =
        t("product-page.anchor.documents") ?? "product-page-documents";
    const { ref } = useAnchorMenu(menuItems);

    return (
        <section
            ref={ref}
            id={anchorId}
            className={classNames(className, style.cDocuments, "py-x-large")}
        >
            <>
                <BalStack layout="vertical" className="container">
                    <BalHeading level="h2">{data.title}</BalHeading>
                    <div className="grid is-multiline">
                        {data.documents.map((document, index) => (
                            <div
                                className={classNames(
                                    "col is-full desktop:is-half fullhd:is-one-third",
                                    style.cDocuments__documentBlock,
                                )}
                                key={`document-${index}`}
                            >
                                <Document
                                    fileName={document.itemDisplayName}
                                    url={document.itemUrlLink}
                                />
                            </div>
                        ))}
                    </div>
                </BalStack>
            </>
        </section>
    );
};

export default Documents;
