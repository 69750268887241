export const formatPrice = new Intl.NumberFormat("nl", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
});

export const formatPriceWithoutDecimal = new Intl.NumberFormat("nl", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const sanitizeCurrencyInput = (value?: string) => {
    if (!value) return 0;
    let sanitizedCurrency = value.split(",")[0];
    sanitizedCurrency = sanitizedCurrency
        .replaceAll(".", "")
        .replaceAll("€", "")
        .replaceAll(" ", "");
    return Number(sanitizedCurrency);
};
