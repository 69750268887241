import { BalButton } from "@baloise/ds-react";
import { MediaQuery } from "@lg-cfa/enums";
import { useMediaQuery } from "@lg-cfa/hooks";
import React from "react";
import { useTranslation } from "react-i18next";

import * as style from "./Help.module.scss";
import { HelpProps } from "./Help.types";

/**
 * Help
 */
const Help: React.FC<HelpProps> = ({
    onHelpClick,
    icon,
    expanded = false,
    small = true,
}) => {
    const isMobile = useMediaQuery(MediaQuery.Mobile);
    const { t } = useTranslation();

    return (
        <BalButton
            size={small ? "small" : ""}
            color="info"
            expanded={expanded}
            rounded
            icon={!icon ? "contact" : undefined}
            onClick={() => (onHelpClick ? onHelpClick() : false)}
            className={style.help}
        >
            {icon && <img src={icon} className="mr-small" />}
            {!isMobile ? (
                <span className={style.helptext}>{t("all.help")}</span>
            ) : (
                <span className={style.helptext}>{t("all.help.mobile")}</span>
            )}
        </BalButton>
    );
};

export default Help;
