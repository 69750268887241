import {
    BalButton,
    BalCard,
    BalCardContent,
    BalHeading,
    BalText,
} from "@baloise/ds-react";
import { MediaQuery } from "@lg-cfa/enums";
import { useMediaQuery } from "@lg-cfa/hooks";
import classnames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import { fetchIconByName } from "../../helpers/icon";

import { NotFoundProps } from "./NotFound.types";

const NotFound: React.FC<NotFoundProps> = ({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onResetClick = () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onContactClick = () => {},
}) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery(MediaQuery.Mobile);

    return (
        <main className="container mt-xxxx-large pb-xxxx-large">
            <div className="container is-compact">
                <BalCard flat square>
                    <BalCardContent className="px-xxx-large py-x-large">
                        <div className="flex flex-direction-column align-items-center text-align-centr">
                            <img
                                src={fetchIconByName(
                                    "ComputerConnectionMonitorError",
                                )}
                                className="mb-large"
                            />
                            <BalHeading
                                level="h3"
                                space="none"
                                className="mb-normal"
                            >
                                <span className="font-weight-light">
                                    {t("notFoundPage.title")}
                                </span>
                            </BalHeading>
                            <BalText space="none" className="mb-medium">
                                {t("notFoundPage.text")}
                            </BalText>
                            <div
                                className={classnames(
                                    "flex gap-medium justify-content-center",
                                    {
                                        "flex-direction-column": isMobile,
                                    },
                                )}
                            >
                                <BalButton
                                    icon="back"
                                    rounded
                                    onClick={onResetClick}
                                    expanded={isMobile}
                                >
                                    {t("notFoundPage.button.reset")}
                                </BalButton>
                                <BalButton
                                    icon="call"
                                    color="info"
                                    rounded
                                    onClick={onContactClick}
                                    expanded={isMobile}
                                >
                                    {t("notFoundPage.button.contact")}
                                </BalButton>
                            </div>
                        </div>
                    </BalCardContent>
                </BalCard>
            </div>
        </main>
    );
};

export default NotFound;
