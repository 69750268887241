import { MediaQuery } from "@lg-cfa/enums";
import { useMediaQuery } from "@lg-cfa/hooks";
import { CSSProperties, useEffect, useState } from "react";

export const useSidebarCalculations = () => {
    const isMobile = useMediaQuery(MediaQuery.Mobile);
    const isTablet = useMediaQuery(MediaQuery.Tablet);
    const isDesktop = useMediaQuery(MediaQuery.Desktop);
    const isWidescreen = useMediaQuery(MediaQuery.Widescreen);
    const isFullHd = useMediaQuery(MediaQuery.FullHD);

    const [SIDE_NAV_WIDTH, setSideNavWidth] = useState<string>("20vw");
    const [PADDING_RIGHT, setPaddingRight] = useState<string>("20vw");
    const [pageStyle, setPageStyle] = useState<CSSProperties>();
    const [sideNavigationStyle, setSideNavigationStyle] =
        useState<CSSProperties>();

    useEffect(() => {
        setPageStyle({
            display: "flex",
            flex: "1 1 auto",
            maxWidth: "100vw",
            height: "calc(100vh - 80px)",
            paddingLeft: SIDE_NAV_WIDTH,
            paddingRight: PADDING_RIGHT,
            overflow: "auto",
        });
        setSideNavigationStyle({
            width: SIDE_NAV_WIDTH,
            height: "calc(100vh - 80px)",
            position: "absolute",
            zIndex: 2,
        });
    }, [SIDE_NAV_WIDTH, PADDING_RIGHT]);

    useEffect(() => {
        if (isFullHd) {
            setSideNavWidth("420px");
            setPaddingRight("320px");
            // setMaxWidth("85vw");
        } else if (isWidescreen) {
            setSideNavWidth("320px");
            setPaddingRight("20vw");
            // setMaxWidth("80vw");
        } else if (isDesktop) {
            setSideNavWidth("25vw");
            setPaddingRight("0");
            // setMaxWidth("100vw");
        } else if (isTablet) {
            setSideNavWidth("30vw");
            setPaddingRight("0");
            // setMaxWidth("100vw");
        } else if (isMobile) {
            setPageStyle({
                display: "flex",
                flex: "1 1 auto",
                maxWidth: "100%",
            });
            setSideNavigationStyle({
                width: "unset",
                maxWidth: "unset",
                height: "56px",
                display: "flex",
                overflowX: "auto",
            });
        }
    }, [isMobile, isTablet, isDesktop, isWidescreen, isFullHd]);

    return { pageStyle, sideNavigationStyle };
};
